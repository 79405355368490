import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import marked from "marked"
import Head from "../components/head"
import PageLayout from "../components/hrbfpagelayout"

export const query = graphql`
  query($slug: String!) {
    contentfulEvents(slug: { eq: $slug }) {
      title
      startDate(formatString: "MMMM D")
      endDate(formatString: "MMMM D, YYYY")
      eventEndDate: endDate(formatString: "D, YYYY")
      eventStartMonth: startDate(formatString: "MMMM")
      eventEndMonth: endDate(formatString: "MMMM")
      displayName
      slug
      headerColor
      transparentHeader
      backgroundImage {
        file {
          url
        }
      }
      bannerLogo {
        file {
          url
        }
      }
      bannerImageLeft {
        file {
          url
        }
      }
      bannerImageRight {
        file {
          url
        }
      }
      bannerImageLeftMobile {
        file {
          url
        }
      }
      bannerImageRightMobile {
        file {
          url
        }
      }
      aboutHeading
      aboutDetails {
        aboutDetails
      }
      recordingsAndSlidesTitle
      sponsorDetails {
        heading
        details {
          details
        }
        host {
          name
          details {
            details
          }
          logo {
            file {
              url
            }
          }
        }
        goldSponsors {
          name
          details {
            details
          }
          logo {
            file {
              url
            }
          }
        }
        silverSponsors {
          name
          details {
            details
          }
          logo {
            file {
              url
            }
          }
        }
        startupSponsors {
          name
          details {
            details
          }
          websiteUrl
          logo {
            file {
              url
            }
          }
        }
        communityPartners {
          name
          details {
            details
          }
          logo {
            file {
              url
            }
          }
        }
        otherSponsors {
          name
          details {
            details
          }
          websiteUrl
          partnerType
          logo {
            file {
              url
            }
          }
        }
      }
      seoMetadata {
        title
        description {
          description
        }
      }
      watchOnDemandUrl
      status
      linkRecordingsAndSlides {
        title
        url
      }
      linksReadMore {
        title
        url
      }
    }
  }
`

const PastEvent = props => {
  const [scrollPosition, setScrollPosition] = useState(0)
  const [selectedSponsor, setSelectedSponsor] = useState()
  useEffect(() => {
    sessionStorage.removeItem("activeDate")
    sessionStorage.removeItem("scrollPosition")
    sessionStorage.removeItem("activeGreen")
    sessionStorage.removeItem("activeOrange")
    sessionStorage.removeItem("activePink")
    sessionStorage.removeItem("activePurple")
    sessionStorage.removeItem("activeBlue")
    sessionStorage.removeItem("activeLavender")
    sessionStorage.removeItem("activeRed")
    sessionStorage.removeItem("activeViolet")
    sessionStorage.removeItem("activeLima")
    const close = e => {
      setScrollPosition(window.pageYOffset)
    }
    window.addEventListener("scroll", close)
  })

  // const getMarkdownText = sponsordetails => {
  //   if (sponsordetails !== null || sponsordetails !== undefined) {
  //     var rawMarkup = marked(sponsordetails, { sanitize: true })
  //     return { __html: rawMarkup }
  //   }
  // }
  useEffect(() => {
    const close = e => {
      if (e.keyCode === 27) {
        document.getElementById("event_popup").style.display = "none"
      }
    }
    window.addEventListener("keydown", close)
    return () => window.removeEventListener("keydown", close)
  }, [])

  useEffect(() => {
    const close = e => {
      if (e.target.id === "event_popup") {
        document.getElementById("event_popup").style.display = "none"
      }
    }
    window.addEventListener("mousedown", close)
    return () => window.removeEventListener("mousedown", close)
  }, [])

  const popupopen = sponsor => () => {
    setSelectedSponsor(sponsor)
    document.getElementById("event_popup").style.display = "flex"
  }
  const popupclose = () => {
    document.getElementById("event_popup").style.display = "none"
  }

  return (
    <PageLayout
      title={props?.data?.contentfulEvents?.displayName?.toUpperCase()}
      bannerBackground={props?.data?.contentfulEvents?.backgroundImage}
      logo={props?.data?.contentfulEvents?.bannerLogo}
      date={
        props?.data?.contentfulEvents?.startDate === null
          ? props?.data?.contentfulEvents?.endDate
          : props?.data?.contentfulEvents?.eventStartMonth ===
            props?.data?.contentfulEvents?.eventEndMonth
          ? `${props?.data?.contentfulEvents?.startDate} - ${props?.data?.contentfulEvents?.eventEndDate}`
          : `${props?.data?.contentfulEvents?.startDate} - ${props?.data?.contentfulEvents?.endDate}`
      }
      slug={props?.data?.contentfulEvents?.slug}
      watchOnDemandUrl={props?.data?.contentfulEvents?.watchOnDemandUrl}
      eventStatus={props?.data?.contentfulEvents?.status}
      headerbtn="WATCH ON DEMAND"
      bannerImageLeft={props?.data?.contentfulEvents?.bannerImageLeft}
      bannerImageLeftMobile={
        props?.data?.contentfulEvents?.bannerImageLeftMobile
      }
      bannerImageRight={props?.data?.contentfulEvents?.bannerImageRight}
      bannerImageRightMobile={
        props?.data?.contentfulEvents?.bannerImageRightMobile
      }
      transparentHeader={props?.data?.contentfulEvents?.transparentHeader}
      scroll={scrollPosition}
    >
      <Head
        title={props?.data?.contentfulEvents?.seoMetadata?.title}
        description={
          props?.data?.contentfulEvents?.seoMetadata?.description?.description
        }
      />
      <div className="pasteventopen_body">
        <h2
          className="pasteventopen_title"
          style={{
            background: `-webkit-linear-gradient(top left, ${props?.data?.contentfulEvents?.headerColor})`,
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          {props?.data?.contentfulEvents?.aboutHeading}
        </h2>
        <div className="pasteventopen_info">
          <p style={{ whiteSpace: "pre-wrap" }}>
            {props?.data?.contentfulEvents?.aboutDetails?.aboutDetails}
          </p>
          {props?.data?.contentfulEvents?.linkRecordingsAndSlides?.url && (
            <>
              <h3>{props?.data?.contentfulEvents?.recordingsAndSlidesTitle}</h3>
              <a
                href={
                  props?.data?.contentfulEvents?.linkRecordingsAndSlides?.url
                }
                target="_blank"
                rel="noreferrer"
              >
                {props?.data?.contentfulEvents?.linkRecordingsAndSlides?.title}{" "}
                &gt;
              </a>
            </>
          )}
          {props?.data?.contentfulEvents?.linksReadMore && (
            <>
              <h3>Read more about Kafka Summit:</h3>
              {props?.data?.contentfulEvents?.linksReadMore?.map(
                (item, index) => {
                  return (
                    <a
                      href={item?.url}
                      target="_blank"
                      rel="noreferrer"
                      key={index}
                    >
                      {item?.title}
                    </a>
                  )
                }
              )}
            </>
          )}
        </div>
        {props?.data?.contentfulEvents?.sponsorDetails !== null && (
          <>
            <div className="peo_sponsors_row">
              <div className="peo_sponsors_title">Sponsors</div>
              {props?.data?.contentfulEvents?.sponsorDetails?.host !== null && (
                <>
                  <div className="peo_sponsors_h2">Host</div>
                  <div className="peo_sponsors_logo">
                    {props?.data?.contentfulEvents?.sponsorDetails?.host?.map(
                      (item, index) => {
                        return (
                          <img
                            src={item?.logo?.file?.url}
                            alt={item?.name}
                            key={index}
                            onClick={
                              item?.details !== null ? popupopen(item) : ""
                            }
                            style={{
                              pointerEvents:
                                item?.details === null ? "none" : "",
                            }}
                          />
                        )
                      }
                    )}
                  </div>
                </>
              )}
            </div>

            <div className="peo_sponsors_row">
              <div className="peo_sponsors_h2">Gold</div>
              <div className="peo_sponsors_logos">
                {props?.data?.contentfulEvents?.sponsorDetails?.goldSponsors?.map(
                  (item, index) => {
                    return (
                      <img
                        src={item?.logo?.file?.url}
                        alt={item?.name}
                        key={index}
                        onClick={item?.details !== null ? popupopen(item) : ""}
                        style={{
                          pointerEvents: item?.details === null ? "none" : "",
                        }}
                      />
                    )
                  }
                )}
              </div>
            </div>

            <div className="peo_sponsors_row">
              <div className="peo_sponsors_h2">Silver</div>
              <div className="peo_sponsors_logos">
                {props?.data?.contentfulEvents?.sponsorDetails?.silverSponsors?.map(
                  (item, index) => {
                    return (
                      <img
                        src={item?.logo?.file?.url}
                        alt={item?.name}
                        key={index}
                        onClick={item?.details !== null ? popupopen(item) : ""}
                        style={{
                          pointerEvents: item?.details === null ? "none" : "",
                        }}
                      />
                    )
                  }
                )}
              </div>
            </div>
            <div className="peo_sponsors_row">
              <div className="peo_sponsors_h2">Startup</div>
              <div className="peo_sponsors_logos">
                {props?.data?.contentfulEvents?.sponsorDetails?.startupSponsors?.map(
                  (item, index) => {
                    return (
                      <img
                        src={item?.logo?.file?.url}
                        alt={item?.name}
                        key={index}
                        onClick={item?.details !== null ? popupopen(item) : ""}
                        style={{
                          pointerEvents: item?.details === null ? "none" : "",
                        }}
                      />
                    )
                  }
                )}
              </div>
            </div>
            <div className="peo_sponsors_row">
              <div className="peo_sponsors_h2">Community Partner</div>
              <div className="peo_sponsors_logos">
                {props?.data?.contentfulEvents?.sponsorDetails?.communityPartners?.map(
                  (item, index) => {
                    return (
                      <img
                        src={item?.logo?.file?.url}
                        alt={item?.name}
                        key={index}
                        onClick={item?.details !== null ? popupopen(item) : ""}
                        style={{
                          pointerEvents: item?.details === null ? "none" : "",
                        }}
                      />
                    )
                  }
                )}
              </div>
            </div>
            {props?.data?.contentfulEvents?.sponsorDetails?.otherSponsors !==
              null && (
              <div className="peo_sponsors_row">
                <div className="peo_sponsors_h2">Other Partner</div>
                <div className="peo_sponsors_logos">
                  {props?.data?.contentfulEvents?.sponsorDetails?.otherSponsors?.map(
                    (item, index) => {
                      return (
                        <figure>
                          <img
                            src={item.logo.file.url}
                            alt={item.name}
                            key={index}
                            onClick={
                              item?.details !== null ? popupopen(item) : ""
                            }
                            style={{
                              pointerEvents:
                                item?.details === null ? "none" : "",
                            }}
                          />
                          {item?.partnerType && (
                            <figcaption>{item?.partnerType}</figcaption>
                          )}
                        </figure>
                      )
                    }
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div className="event_popup" id="event_popup">
        <div className="event_popup_body">
          <div className="event_popup_close">
            <span onClick={popupclose}>X</span>
          </div>
          <div className="event_popup_log">
            <img src={selectedSponsor?.logo?.file?.url} alt="logo" />
          </div>
          <div className="event_popup_title">{selectedSponsor?.name}</div>
          <div
            className="event_popup_info"
            dangerouslySetInnerHTML={{
              __html: marked.parse(selectedSponsor?.details?.details ?? ""),
            }}
          ></div>
        </div>
      </div>
    </PageLayout>
  )
}
export default PastEvent
